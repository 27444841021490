<template>
  <b-overlay :show="loadingRoutes">
    <div
      class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1"
    >
      <div class="p-2">
        <b-button
          v-if="userData.role !== 'ADMINISTRATION'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mb-0"
          aria-controls="sidebar-add-new-event"
          :aria-expanded="String(isEventHandlerModalActive)"
          variant="primary"
          block
          @click="
            $emit('update:isEventHandlerModalActive', true);
            $emit('forProvider', false);
          "
        >
          {{ $t("agenda.add_event_customer") }}
        </b-button>
        <b-button
          v-if="userData.role !== 'ADMINISTRATION'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mb-3"
          aria-controls="sidebar-add-new-event"
          :aria-expanded="String(isEventHandlerModalActive)"
          variant="outline-primary"
          block
          @click="
            $emit('update:isEventHandlerModalActive', true);
            $emit('forProvider', true);
          "
        >
          {{ $t("agenda.add_event_provider") }}
        </b-button>
        <div>
          <!-- Types -->
          <h5 class="app-label section-label mb-1">
            <span class="align-middle">{{ $t("common.calendars") }}</span>
          </h5>
          <b-form-checkbox
            v-model="checkAll"
            class="mb-1"
          >
            {{ $t("agenda.view_all") }}
          </b-form-checkbox>
          <b-form-group>
            <b-form-checkbox-group
              v-model="selectedCalendars"
              name="event-filter"
              stacked
            >
              <b-form-checkbox
                v-for="item in calendarOptions"
                :key="item.label"
                name="event-filter"
                :value="item.value"
                class="mb-1"
                :class="`custom-control-${item.color}`"
              >
                {{ $t("agenda." + item.value.toLowerCase()) }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <!-- Day picker -->
          <h5 class="app-label section-label mb-1 mt-1">
            <span class="align-middle">{{ $t("agenda.day") }}</span>
          </h5>
          <flat-pickr
            v-model="date"
            class="form-control"
            :config="{ enableTime: false, dateFormat: 'd-m-Y' }"
            @input="setDay"
          />

          <!-- Mechanics -->
          <h5 class="app-label section-label mb-1 mt-1">
            <span class="align-middle">{{ $t("agenda.mechanics") }}</span>
          </h5>
          <b-form-group>
            <v-select
              v-if="userData.role === 'MECHANICS'"
              v-model="isMechanic"
              readonly
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :get-option-label="(mechanic) => mechanic.name"
              :clearable="false"
            >
              <template #option="{ name }">
                <span> {{ name }}</span>
              </template>

              <template #selected-option="{ name, id }">
                <span v-if="id === null">{{ $t("agenda.all") }}</span>
                <span v-else> {{ name }}</span>
              </template>
            </v-select>
            <v-select
              v-else
              v-model="selectedMechanic"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="mechanics"
              :get-option-label="(mechanic) => mechanic.name"
              :clearable="false"
            >
              <template #option="{ name }">
                <span> {{ name }}</span>
              </template>

              <template #selected-option="{ name, id }">
                <span v-if="id === null">{{ $t("agenda.all") }}</span>
                <span v-else> {{ name }}</span>
              </template>
            </v-select>
          </b-form-group>

          <!-- Routes -->
          <h5 class="app-label section-label mb-1 mt-1">
            <span class="align-middle">Routes</span>
          </h5>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="selectedMechanic.name === $t('agenda.all')"
            class="my-1"
            variant="outline-primary"
            block
            @click="coordinates"
          >
            <feather-icon
              icon="MapIcon"
              class="mr-25"
            />
            {{ $t("agenda.get_routes") }}
          </b-button>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dbUser from '@/models/user'
import dbProvider from '@/models/provider'
import dbAgenda from '@/models/agenda'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    flatPickr,
    vSelect,
    BOverlay,
  },
  props: {
    isEventHandlerModalActive: {
      type: Boolean,
      require: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    refCalendar: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      date: new Date(),
      loadingRoutes: false,
      isMechanic: {},
    }
  },
  computed: {
    mechanics() {
      const arr = this.$store.state.app.system?.users_map?.MECHANICS || []
      const map = new Map()
      arr.forEach(obj => {
        Object.keys(obj).forEach(key => {
          map.set(key, obj[key])
        })
      })

      const array = [{ name: this.$t('agenda.all'), id: null }]
      map.forEach((val, key) => {
        array.push({ id: Number(key), name: val })
      })
      return array
    },
  },
  created() {
    if (this.userData.role === 'MECHANICS') {
      this.isMechanic = { id: this.userData.id, name: this.userData.username }
      this.selectedMechanic = this.isMechanic
    }
  },
  methods: {
    async coordinates() {
      this.loadingRoutes = true
      const mechanicInfo = await dbUser.getById({
        userId: this.selectedMechanic.id,
      })
      const mechanicCoordinate = `${mechanicInfo.latitude},${mechanicInfo.longitude}`

      let link = 'https://www.google.com/maps/dir'
      const todayEvents = []
      const api = this.refCalendar.getApi()
      const date = api.getDate()
      const events = api.getEvents()

      events.forEach(event => {
        if (event.start.toDateString() === date.toDateString()) todayEvents.push(event.extendedProps)
      })

      const locations = this.$store.getters['calendar/getCustomersLocations'](
        todayEvents,
      )

      // get providers locations
      // eslint-disable-next-line no-restricted-syntax
      for (const event of todayEvents) {
        if (event.customer_id == null) {
          // eslint-disable-next-line no-await-in-loop
          const res = (await dbAgenda.getById(event.agenda_id)).data
          // eslint-disable-next-line no-await-in-loop
          const provider = await dbProvider.getById(res.provider.id)
          locations.push(`${provider.latitude}, ${provider.longitude}`)
        }
      }

      link = link.concat(`/${mechanicCoordinate}`)
      locations.forEach(element => {
        link = link.concat(`/${element}`)
      })
      link = link.concat(`/${mechanicCoordinate}`)

      this.loadingRoutes = false

      if (locations.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No events today',
            icon: 'CompassIcon',
            variant: 'info',
          },
        })
      } else {
        window.open(link, '_blank')
      }
    },
    setDay(date) {
      const dateParts = date.split('-')
      const dateObject = new Date(
        +dateParts[2],
        dateParts[1] - 1,
        +dateParts[0],
      )
      const api = this.refCalendar.getApi()
      api.gotoDate(dateObject)
    },
  },
  setup() {
    const {
      calendarOptions,
      customerOptions,
      selectedCalendars,
      checkAll,
      selectedMechanic,
    } = useCalendarSidebar()

    return {
      calendarOptions,
      customerOptions,
      selectedCalendars,
      checkAll,
      selectedMechanic,
    }
  },
}
</script>

<style></style>
